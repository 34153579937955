import { j as t } from "./jsx-runtime-B6kdoens.js";
import { useState as M } from "react";
import { C as N, c as a } from "./react-select-creatable.esm-xuUGCztE.js";
import "./Accordion-Bzgs65lf.js";
import { A as l } from "./Avatar-95vFNu6x.js";
import { B as x } from "./Badge-C0bj4DFU.js";
import "./Button-ChOUYHf0.js";
import "./DropdownMenu-CdmbSELo.js";
import { I as b } from "./Icon-CK-T4e-h.js";
import "./Input-Buc26SKc.js";
import "./Label-CI8_Vg74.js";
import { T as u } from "./Text-Bn-rHqca.js";
import { T as c } from "./Tooltip-Dy7rqQaX.js";
import { c as v } from "./utils-CJ9afRe1.js";
import { S as y } from "./Stack-DHVjAP0n.js";
import "./Switch-BgHWRaX7.js";
import "./Toast-DkQ3kscR.js";
import "./Toggle-Cn0qt44_.js";
import { S as O } from "./Separator.component-BNi038DN.js";
import { U as f } from "./UserNameText.component-O9OvOO7q.js";
const S = (e) => {
  const s = e.split(" "), r = s[0].substring(0, 1).toUpperCase(), i = s.length > 1 ? s[1].substring(0, 1).toUpperCase() : "";
  return `${r}${i}`;
}, L = (e) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(e), V = (e) => /* @__PURE__ */ t.jsx(a.DropdownIndicator, { ...e, children: /* @__PURE__ */ t.jsx(b, { name: "NavArrowDown", strokeWidth: 1.5, width: 20, height: 20 }) }), z = (e) => /* @__PURE__ */ t.jsxs(a.Option, { ...e, children: [
  e.data.avatar,
  /* @__PURE__ */ t.jsx(
    f,
    {
      size: "sm",
      truncate: !0,
      user: e.data.user,
      isMe: e.data.isMe,
      highlight: e.selectProps.inputValue
    }
  )
] }), I = (e) => /* @__PURE__ */ t.jsx(a.MultiValueRemove, { ...e, children: /* @__PURE__ */ t.jsx(b, { name: "Cancel", strokeWidth: 1.5, width: 20, height: 20 }) }), P = (e) => /* @__PURE__ */ t.jsx(a.GroupHeading, { ...e, children: e.children }), k = (e) => {
  if (!e.data.onLoan) {
    const s = e.options.filter((r) => w(r.label, e.selectProps.inputValue)).length;
    return /* @__PURE__ */ t.jsxs("div", { className: "flex flex-col gap-4 pl-2", children: [
      /* @__PURE__ */ t.jsxs(y, { row: !0, space: "sm", children: [
        /* @__PURE__ */ t.jsx(x, { size: "md", circle: !0, variant: "secondary", children: s }),
        /* @__PURE__ */ t.jsx(
          u,
          {
            size: "sm",
            weight: "medium",
            as: "h1",
            children: e.data.label
          }
        )
      ] }),
      /* @__PURE__ */ t.jsx("div", { className: "flex flex-col gap-2 text-sm", children: e.children })
    ] });
  }
  return /* @__PURE__ */ t.jsx(a.Group, { ...e, children: e.children });
}, G = (e) => /* @__PURE__ */ t.jsxs(a.Menu, { ...e, children: [
  /* @__PURE__ */ t.jsx(u, { size: "sm", weight: "medium", className: "px-3 pt-3 pb-2", children: "Send to" }),
  /* @__PURE__ */ t.jsx(O, {}),
  e.children
] }), R = (e) => {
  const s = e.options.reduce((r, i) => "options" in i && i.onLoan ? r + i.options.filter((d) => w(d.label, e.selectProps.inputValue)).length : r, 0);
  return /* @__PURE__ */ t.jsxs(a.MenuList, { ...e, children: [
    s > 0 && /* @__PURE__ */ t.jsxs(y, { row: !0, space: "sm", children: [
      /* @__PURE__ */ t.jsx(x, { size: "md", circle: !0, variant: "secondary", children: s }),
      /* @__PURE__ */ t.jsx(
        u,
        {
          size: "sm",
          weight: "medium",
          as: "h1",
          children: "On Loan"
        }
      )
    ] }),
    e.children
  ] });
}, T = (e) => /* @__PURE__ */ t.jsx(a.MenuPortal, { ...e, "data-toast": "true", children: e.children }), D = (e) => /* @__PURE__ */ t.jsx(a.NoOptionsMessage, { ...e, children: /* @__PURE__ */ t.jsxs(y, { space: "sm", items: "center", justify: "center", className: "flex-1", children: [
  /* @__PURE__ */ t.jsx(b, { name: "BigExclamation", width: 64, height: 64, className: "text-black-10" }),
  /* @__PURE__ */ t.jsx(u, { className: "text-black-primary font-medium", size: "sm", children: "User not found" })
] }) }), F = (e) => /* @__PURE__ */ t.jsx(a.MultiValue, { ...e, children: /* @__PURE__ */ t.jsxs(c, { children: [
  /* @__PURE__ */ t.jsx(c.Trigger, { asChild: !0, children: /* @__PURE__ */ t.jsx(
    f,
    {
      size: "sm",
      truncate: !0,
      user: e.data.user,
      isMe: e.data.isMe,
      highlight: e.selectProps.inputValue
    }
  ) }),
  /* @__PURE__ */ t.jsx(
    c.Content,
    {
      align: "start",
      side: "bottom",
      alignOffset: 0,
      children: /* @__PURE__ */ t.jsx(
        f,
        {
          size: "sm",
          user: e.data.user,
          isMe: e.data.isMe,
          highlight: e.selectProps.inputValue
        }
      )
    }
  )
] }) }), U = (e) => {
  const s = e.getValue().length;
  return /* @__PURE__ */ t.jsxs(a.ValueContainer, { ...e, children: [
    e.children,
    s > 1 && /* @__PURE__ */ t.jsxs(x, { variant: "secondary", className: "py-0.5 h-5 group-focus-within/select:hidden mt-1 border border-gray-neutral-80", children: [
      "+",
      s - 1,
      " more"
    ] })
  ] });
}, g = {
  base: "border-0  rounded-none bg-white hover:cursor-text text-sm",
  focus: "",
  nonFocus: ""
}, E = "text-gray-500 pl-1 group-focus-within/select:py-0.5", H = "pl-1 group-focus-within/select:py-0.5 group-focus-within/select:flex", W = "p-1 gap-1 group-focus-within/select:pt-1 group-focus-within/select:flex-wrap", $ = "leading-7 ml-1", A = "bg-gray-neutral-30 truncate items-center py-0.5 h-5 pl-2 rounded-xl pr-1 gap-2 group-focus-within/select:flex [&:not(:first-of-type)]:hidden  group-focus-within/select:first:mt-0 first:mt-1 first:flex", B = "leading-6 py-0.5 ", q = "hover:text-red-800 hover:border-red-300", J = "p-1 gap-1 items-start flex flex-row", K = "hidden w-0 overflow-hidden", Q = "w-0", X = "p-1 hover:bg-gray-100 text-black-primary rounded-md cursor-pointer", Y = "mt-2 border-gray-neutral-80 border bg-white rounded-lg z-dialog", Z = "text-black-primary text-sm font-medium", p = {
  base: "text-sm aria-disabled:opacity-50 aria-disabled:cursor-default truncate overflow-hidden cursor-pointer rounded-md p-2 flex flex-row gap-2 items-center",
  focus: "bg-gray-neutral-30 active:bg-gray-200",
  selected: "text-gray-500"
}, _ = "group/select", ee = "pl-2 flex flex-col gap-3 text-sm [&>div]:flex [&>div]:flex-col [&>div]:gap-2 [&>div:last-of-type]:pl-2", te = "p-3 flex flex-col gap-4 scrollbar-stable", ne = " h-60 max-h-full flex items-center justify-center", Me = (e) => {
  const [s, r] = M(e.options), i = (n) => {
    e.onChange(n);
  }, d = (n) => {
    const o = {
      value: n,
      isMe: !1,
      user: {
        givenName: n,
        familyName: ""
      },
      label: n,
      onLoan: !1,
      avatar: /* @__PURE__ */ t.jsx(l, { size: "sm", children: /* @__PURE__ */ t.jsx(l.Fallback, { className: "bg-yellow-60", children: S(n) }) })
    };
    r((j) => {
      const m = j.find((h) => !h.onLoan);
      return [
        ...j.filter((h) => h.onLoan),
        m ? {
          ...m,
          options: [...m.options, o]
        } : {
          ...se,
          options: [o]
        }
      ];
    }), e.onChange([...e.value, o]);
  }, C = (n) => ({
    value: n,
    isMe: !1,
    user: {
      givenName: n,
      familyName: ""
    },
    label: n,
    onLoan: !1,
    avatar: /* @__PURE__ */ t.jsx(l, { size: "sm", children: /* @__PURE__ */ t.jsx(l.Fallback, { className: "bg-yellow-60", children: S(n) }) })
  });
  return /* @__PURE__ */ t.jsx(c.Provider, { children: /* @__PURE__ */ t.jsx(
    N,
    {
      isMulti: !0,
      isValidNewOption: L,
      getNewOptionData: C,
      closeMenuOnSelect: !1,
      hideSelectedOptions: !1,
      unstyled: !0,
      styles: {
        container: (n) => ({
          ...n,
          width: "100%",
          overflow: "hidden"
        }),
        input: (n) => ({
          ...n,
          order: 99,
          "input:focus": {
            boxShadow: "none"
          }
        }),
        valueContainer: (n) => ({
          ...n,
          flexWrap: "nowrap"
        }),
        menu: (n) => ({
          ...n,
          zIndex: 1304
        }),
        // On mobile, the label will truncate automatically, so we want to
        // override that behaviour.
        multiValueLabel: (n) => ({
          ...n
        }),
        option: (n) => ({
          ...n,
          display: "flex",
          cursor: "pointer"
        }),
        menuPortal: (n) => ({
          ...n,
          zIndex: 1304,
          pointerEvents: "auto"
        }),
        control: (n) => ({
          ...n,
          transition: "none",
          borderWidth: 0,
          boxShadow: "none"
        }),
        noOptionsMessage: (n) => ({
          ...n,
          backgroundColor: "transparent"
        })
      },
      components: {
        Option: z,
        Menu: G,
        Group: k,
        GroupHeading: P,
        MenuList: R,
        DropdownIndicator: V,
        MultiValueRemove: I,
        ValueContainer: U,
        MenuPortal: T,
        MultiValue: F,
        NoOptionsMessage: D
      },
      classNames: {
        control: ({ isFocused: n }) => v(
          n ? g.focus : g.nonFocus,
          g.base
        ),
        placeholder: () => E,
        input: () => H,
        container: () => _,
        valueContainer: () => W,
        singleValue: () => $,
        multiValue: () => A,
        multiValueLabel: () => B,
        multiValueRemove: () => q,
        indicatorsContainer: () => J,
        clearIndicator: () => K,
        indicatorSeparator: () => Q,
        dropdownIndicator: () => X,
        menu: () => Y,
        groupHeading: () => Z,
        group: () => ee,
        menuList: () => te,
        option: ({ isFocused: n, isSelected: o }) => v(
          n && p.focus,
          o && p.selected,
          p.base
        ),
        noOptionsMessage: () => ne
      },
      ...e,
      options: s,
      className: "z-dialog",
      menuPlacement: "auto",
      isClearable: !1,
      onCreateOption: d,
      menuPosition: "fixed",
      menuPortalTarget: document.body,
      openMenuOnClick: !1,
      openMenuOnFocus: !1,
      menuShouldScrollIntoView: !1,
      isOptionDisabled: (n) => e.value.some((o) => o.value === n.value),
      tabSelectsValue: !1,
      filterOption: (n, o) => o ? w(n.label, o) : n.data.onLoan,
      onChange: i
    }
  ) });
}, w = (e, s) => e.toLowerCase().includes(s.toLowerCase()), se = {
  label: "Not on loan",
  options: [],
  onLoan: !1
};
export {
  Me as default
};
